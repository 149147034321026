import { Link } from "react-router-dom"
import Button from "./form/Button"

function HeroSection() {
  return (
    <div className="lg:flex lg:justify-between mt-20 mb-32 lg:mb-36 lg:mt-28 gap-20">
      <h1 className=" text-neBlue lg:w-1/2 text-5xl lg:text-7xl leading-normal lg:leading-relaxed text-left lg:text-center font-semibold">
        Ready To Grow Revenue?
      </h1>
      <div className="leading-loose lg:w-1/2 text-left item-center font-thin text-lg mt-16 text-neDarkBlue">
        <b className="font-semibold text-neBlue">NextEdge Studio</b> stands at the forefront of innovation, blending visionary thinking
        with cutting-edge design. Specializing in future-forward branding, web development,
        and SEO strategies, this brand is dedicated to helping businesses leap into the next
        generation of digital success. <br />
        
        <div className="text-center mt-7">
        <Link to={'/contact'} >
          <Button dark css=''>Request Proposal</Button>
        </Link>
        </div>
      </div>
    </div>
  )
}

export default HeroSection