import React, { useEffect, useContext } from "react"

function LoadingIcon() {
  return (
    <>
      
    </>
  )
}

export default LoadingIcon