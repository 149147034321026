import React from "react"
import Header from "../components/shared/Header";
import Layout from "../components/shared/Layout";
import HeroSection from "../components/HeroSection";
import Carousel from "../components/Carousel";
import Services from "../components/Services";
import BreakText from "../components/BreakText";
import OurWork from "../components/OurWork";

function HomePage() {
  return (
    <Layout title="Home">
      <Header />
      <div className="container-fluid">
        <div className="px-3 md:px-5">
          <HeroSection />
          <Carousel />
          <Services />
          <BreakText />
          <OurWork />
        </div>
      </div>
    </Layout>
  )
}

export default HomePage