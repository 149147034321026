import React, { useEffect, useContext } from "react"

function Container(props) {
  return (
    <div className={"layout " + props.css}>
      {props.children}
    </div>
  )
}

export default Container