import React, { useEffect, useContext } from "react"
import Navs from "./Navs"
import StateContext from "../../context/StateContext";


function SideBar() {
    const appState = useContext(StateContext)
    const { sideBar } = appState

    return (
        <aside className={`bg-neWhite h-full overflow-y-auto transform 
         ${sideBar ? 'translate-x-0 animate-slide-in border-r' : '-translate-x-full animate-slide-out'} 
            transition-transform duration-1000 ease-in-out
        `}>
            <div className="pt-32 text-center flex flex-col gap-12">
                <Navs sideBar />
            </div>
        </aside>
    )
}

export default SideBar