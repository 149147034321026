import React from "react"

function BreakText() {
  return (
    <div className="my-20">
        <h1 className="text-neCoral font-light text-center text-4xl lg:text-6xl text-left break-text">
            The best companies choose to work with NextEdge Studio
        </h1>
    </div>
  )
}

export default BreakText