import React from "react"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Carousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500, // Adjust the transition speed
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, // Time each slide is shown
        fade: true, // Enables fade transition
        cssEase: 'ease-in-out', // Smooth transition
        arrows: true, // Show previous/next arrows
    };

    const images = [
        { src: 'img/carousel/carousel-1.jpg' },
        { src: 'img/carousel/carousel-2.jpg' },
        { src: 'img/carousel/carousel-3.jpg' },
        { src: 'img/carousel/carousel-4.jpg' }
    ]

    return (
        <div className="lg:px-20 mb-32 lg:mb-60">
            <Slider {...settings} className="">
                {images.map((image, index) => {
                    return (
                        <div key={index}>
                            <img src={image.src} alt="carousel" className="w-full"  />
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default Carousel