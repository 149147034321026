import React, { useEffect, useContext } from "react"
import Button from "./form/Button"

function OurWork() {
    return (
        <div className="my-40 ">
            <small className="opacity-40">Projects</small>
            <h1 className="mb-5 text-neBlue">Our Work</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                <div className="">
                    <div className="work-card">
                        <img src="/img/our_work/work_1.png" alt="" />
                    </div>
                    <h6 className="text-xl lg:text-3xl text-center font-light mt-4 ml-3 cursor-pointer work-name">
                        Arcs & Glass
                        <span className="pl-2">
                            <ion-icon name="trending-up-outline"></ion-icon>
                        </span>
                    </h6>
                </div>
                <div className="">
                    <div className="work-card ">
                        <img src="/img/our_work/work_1.png" alt="" />
                    </div>
                    <h6 className="text-xl lg:text-3xl font-light mt-4 ml-3 cursor-pointer text-center work-name">
                        Arcs & Glass
                        <span className="pl-2">
                            <ion-icon name="trending-up-outline"></ion-icon>
                        </span>
                    </h6>
                </div>
            </div>

            <div className="my-10 lg:my-20 lg:mx-20">
                <div className="work-card">
                    <img src="/img/our_work/work_1.png" alt="" />
                </div>
                <h6 className="text-xl lg:text-3xl font-light mt-4 ml-3 cursor-pointer text-center work-name">
                    Arcs & Glass
                    <span className="pl-2">
                        <ion-icon name="trending-up-outline"></ion-icon>
                    </span>
                </h6>
            </div>

            <div className="text-center">
                <Button dark css="w-3/4"> See Our Works</Button>
            </div>

        </div>
    )
}

export default OurWork