import React, { useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import WOW from 'wowjs';
import 'animate.css';
import StateContext from "../../context/StateContext";
import DispatchContext from "../../context/DispatchContext";
import Navs from "./Navs";

function Header() {
    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext)
    const { sideBar } = appState
    // console.log(window.innerWidth)
    useEffect(() => {

        // Spinner
        const spinnerTimeout = setTimeout(() => {
            const spinnerElement = document.getElementById('spinner');
            if (spinnerElement) {
                spinnerElement.classList.remove('show');
            }
        }, 1);

        // Initiate WOW.js
        new WOW.WOW({
            live: false,
        }).init();

        // Sticky Navbar
        const handleScroll = () => {
            const stickyNav = document.querySelector('.sticky-top');
            if (window.scrollY > 100) {
                stickyNav?.classList.add('shadow-sm');
                stickyNav?.style.setProperty('top', '0px');
            } else {
                stickyNav?.classList.remove('shadow-sm');
                stickyNav?.style.setProperty('top', '-100px');
            }
        };
        window.addEventListener('scroll', handleScroll);

        // Back to Top Button
        const backToTop = document.querySelector('.back-to-top');
        const handleBackToTopScroll = () => {
            if (window.scrollY > 300) {
                backToTop?.style.setProperty('display', 'block');
            } else {
                backToTop?.style.setProperty('display', 'none');
            }
        };
        window.addEventListener('scroll', handleBackToTopScroll);

        // Cleanup
        return () => {
            clearTimeout(spinnerTimeout);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', handleBackToTopScroll);
        };
    }, []);

    return (
        <>
            <div className="flex justify-between sticky-top  container-fluid pt-2 lg:py-0 z-50 bg-neWhite mt-3">
                <Link to="" className="">
                    <img src="img/Ne-logo.png" className="w-20 lg:w-28 lg:hidden" alt="logo" />
                    <img src={`img/nes-logo.png`} className="hidden lg:flex w-20 lg:w-96 -mt-1" alt="logo" />
                </Link>

                <div className="hidden lg:flex">
                    <div className="navbar gap-12 py-0 ">
                        <Navs />
                    </div>
                </div>

                <div onClick={() => appDispatch({ type: "toggleSideBar" })}
                    className="flex lg:hidden hover:cursor-pointer">
                    <div className="text-neDarkBlue text-5xl navbar ">
                        {sideBar ? <div className="ease-in-out duration-1000"><ion-icon name="close-outline"></ion-icon></div>
                            : <div className="ease-in-out duration-1000"><ion-icon name="reorder-two-outline"></ion-icon></div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header